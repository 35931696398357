import "./style.css";
import { Tooltip } from "@mui/material";
import { CloseIcon } from "../../components/svg";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { EachProduct } from "../../components/eachProduct";
import { PageNavigation } from "../../components/pageNavigation";
import { SingleProduct } from "../../components/popup/singleProduct";
import { ManufacturerDescription } from "../../components/popup/manufacturerDescription";
import { SingleManufacturerSkeleton } from "../../components/skeletons/singleManufacturer";
import { isAndroid, isIOS } from "react-device-detect";
import { useAppLocation } from "react-app-location";
import { useLocation } from 'react-router-dom';
import {
  FilterCategories,
  GetSingleManufacturer,
} from "../../Redux/action/manufacturer_ation";
import {
  CheckboxChecked,
  CheckboxNotChecked,
  CubicIcon,
  DocumentIcon,
  InfoIcon,
  InternetIcon,
  RemoveIcon,
  ReviewIcon,
  TelegramIcon,
  VerificationIcon,
  WhatsappIcon,
} from "../../components/svg";

export const WishListFirst = () => {
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // const dispatch = useDispatch();
  // const manufacturer = useSelector(
  //   (st) => st.Manufacturer_reducer.singleManufacturerUser
  // );
  // const categories = useSelector(
  //   (st) => st.Manufacturer_reducer.singleManufacturerCategories
  // );
  // const cities = useSelector(
  //   (st) => st.Manufacturer_reducer.singleManufacturerCities
  // );
  // const products = useSelector(
  //   (st) => st.Manufacturer_reducer.singleManufacturerProducts
  // );
  // const filteredProducts = useSelector(
  //   (st) => st.Manufacturer_reducer.singleManufacturerFilteredProducts
  // );
  // const [openSingleProductPopup, setOpenSingleProductPopup] = useState(false);
  // const [companyName] = useState(window.location.pathname.split("/")[1]);
  // const [selectedProduct, setSelectedProduct] = useState(null);
  // const [checked, setChecked] = useState(false);
  // const [openDescription, setOpenDescription] = useState(false);
  // const [myCategories, setMyCategories] = useState([]);
  // const [productsToShow, setProductsToShow] = useState(products);
  // const [popup, setPopup] = useState(false);
  // const [whatsappOpen, setWhatsappOpen] = useState(false);

  // useEffect(() => {
  //   dispatch(GetSingleManufacturer(companyName));
  // }, [companyName, dispatch]);

  // useEffect(() => {
  //   if (manufacturer) {
  //     // console.log(manufacturer?.id, "idddddddd");
  //     if (
  //       manufacturer?.show_room?.includes("Да") ||
  //       manufacturer?.show_room?.includes("да")
  //     ) {
  //       setChecked(true);
  //     } else {
  //       setChecked(false);
  //     }
  //   }
  // }, [manufacturer]);

  // useEffect(() => {
  //   if (categories) {
  //     let category = [];
  //     categories
  //       ?.filter((e) => e.parent_category_name)
  //       ?.forEach((element) => {
  //         category.push({
  //           selected: false,
  //           name: element.parent_category_name,
  //           id: element.parent_category_id,
  //         });
  //       });
  //     setMyCategories(category);
  //   }
  // }, [categories]);

  // useEffect(() => {
  //   if (myCategories?.every((e) => e.selected === false)) {
  //     setProductsToShow(products);
  //   } else {
  //     myCategories?.forEach((element) => {
  //       element?.selected &&
  //         dispatch(FilterCategories(element?.name, manufacturer?.id));
  //     });
  //     setProductsToShow(filteredProducts);
  //   }
  // }, [myCategories, filteredProducts, products, manufacturer, dispatch]);

  // const toggleCategorySelection = (categoryId) => {
  //   setMyCategories((prevCategories) =>
  //     prevCategories?.map((category) => ({
  //       ...category,
  //       selected: category?.id === categoryId ? !category?.selected : false,
  //     }))
  //   );
  // };

  // function handleClick(e) {
  //   setSelectedProduct(e);
  //   setOpenSingleProductPopup(true);
  // }

  // function handleProtocol(url) {
  //   const protocolRegex = /^https?:\/\//i;
  //   if (protocolRegex.test(url)) {
  //     return url;
  //   } else {
  //     return "http://" + url;
  //   }
  // }

  // const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  // console.log(location.pathname, 'pathname');
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    const loc = location.pathname
    window.addEventListener("resize", handleResize);

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    const openAppOrAppStore = () => {
      // const appSchemeUrl = `mychat://id/${manufacturer?.id}`;
      const appStoreUrl = "https://apps.apple.com/us/app/your-app-id"; // Replace with your App Store URL

      if (
        /iPad|iPhone/.test(navigator.platform) &&
        loc
      ) {
        window.location = `mychat://id/${loc}`;
        // setTimeout(function () {
        //   window.location = `/${companyName}`;
        // }, 1000);
      } else {
      }
      if (isAndroid && loc) {
        window.location = `mychat://id/${loc}`;
        // setTimeout(function () {
        //   window.location = `/${companyName}`;
        // }, 1000);
      }
    };

    openAppOrAppStore();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const handleButtonClick = () => {
  //   const isAndroid = /Android/i.test(navigator.userAgent);
  //   const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  //   const appPackageName = "com.JustCode.RefectioApp"; // Android

  //   if (isIOS) {
  //     window.location.href = "https://apps.apple.com/app/id6475083266";
  //   } else if (isAndroid) {
  //     window.location.href = `market://details?id=${appPackageName}`;
  //   } else {
  //   }
  // };


  return (
    <div className="wishlist">


    </div>
  );
};
